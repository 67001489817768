 import * as React from "react"
 import { Helmet } from "react-helmet"
 import { useStaticQuery, graphql } from "gatsby"

 type MetaProps = JSX.IntrinsicElements["meta"]

 export interface SeoProps {
     description?: string
     lang?: string
     meta?: MetaProps[]
     title?: string
 }

 const Seo = ({ description, lang = "en-US", meta = [], title }: SeoProps) => {
     const { site } = useStaticQuery(
         graphql`
             query {
                 site {
                     siteMetadata {
                         title
                         description
                         social {
                             twitter
                             github
                             linkedin
                         }
                     }
                 }
             }
         `
     )

     const metaDescription = description || site.siteMetadata.description
     const defaultTitle = site.siteMetadata?.title

     return (
         <Helmet
             htmlAttributes={{
                 lang,
             }}
             title={title}
             titleTemplate={defaultTitle ? `%s — ${defaultTitle}` : undefined}
             meta={[
                 {
                     name: `description`,
                     content: metaDescription,
                 },
                 {
                     property: `og:title`,
                     content: title,
                 },
                 {
                     property: `og:description`,
                     content: metaDescription,
                 },
                 {
                     property: `og:type`,
                     content: `website`,
                 },
                 {
                     name: `twitter:card`,
                     content: `summary`,
                 },
                 {
                     name: `twitter:creator`,
                     content: site.siteMetadata?.social?.twitter || ``,
                 },
                 {
                     name: `twitter:title`,
                     content: title,
                 },
                 {
                     name: `twitter:description`,
                     content: metaDescription,
                 },
             ].concat((meta as unknown) as any)}
         />
     )
 }
 export default Seo
